@use 'styles/colors';
@use 'styles/utils';

.standard {
  color: colors.$main-content-text;
  font-size: 18px;
}

.hoverable {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.inline {
  display: inline;
}

.meta {
  color: colors.$helper-text;
}

.lightGray {
  color: colors.$helper-text;
}

.bold {
  font-weight: bold;

  &.meta {
    color: colors.$helper-text;
  }

  &.error {
    color: colors.$cardinal;
  }
}

.semibold {
  font-weight: 600;

  &.meta {
    color: colors.$helper-text;
  }

  &.error {
    color: colors.$cardinal;
  }
}

.thin {
  font-weight: 200;
}

.livid {
  color: colors.$livid-700;
}

.secondary {
  color: colors.$interactive;
  &.hoverable:hover {
    color: colors.$interactive-hover;
  }
}

.error {
  color: colors.$cardinal;
}

.nowrap {
  white-space: nowrap;
}
