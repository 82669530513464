@use 'styles/colors';
@use 'styles/utils';

.select {
  width: 300px;
}

.dropdown {
  border-radius: 4px;
  position: relative;
}

.dropdownBtn {
  margin-right: 1rem;
}

$shadow: hsla(218, 50%, 10%, 0.1);
.menu {
  background-color: colors.$white;
  border-radius: 4;
  box-shadow: 0 0 0 1px colors.$box-shadow, 0 4px 11px colors.$box-shadow;
  margin-top: 8;
  position: absolute;
  z-index: 2;
}

.blanket {
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 1;
}

.selectAllBtn {
  margin: 0 0.3rem;
  pointer-events: all;
  z-index: 100;
}

.optionContainer {
  display: flex !important;
}

.option {
  flex: 1;
}

.optionIcon {
  margin-right: 10px;
}
