@use 'styles/colors';
@use 'styles/utils';

.container {
  @include utils.ml1;
  background-color: colors.$white;
  border: utils.$base-border-style;
  border-style: solid;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  flex-shrink: 0;
  padding: 0px 0 16px 0;
  width: 12rem;
  white-space: nowrap;
}
.linksContainer {
  padding-top: 16px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  align-items: center;
  padding-left: 12px;
  color: colors.$deep-gray !important;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 6px;
  position: relative;
  transition: color 0.2s linear;

  &--selected {
    color: colors.$interactive !important;
    cursor: default !important;
    font-weight: bold;

    .icon {
      color: transparent !important;
    }

    &:after {
      background-color: colors.$interactive !important;
    }
    &:hover .icon {
      display: none;
    }
  }

  &:hover {
    color: colors.$interactive-hover !important;

    .icon {
      color: colors.$interactive-hover !important;
    }
  }

  &--disabled {
    color: colors.$deep-gray!important;
    cursor: default;

    &:hover {
      color: colors.$deep-gray!important;

      .icon {
        color: transparent !important;
      }
    }
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &:after {
    background-color: transparent;
    border-radius: 0.15rem;
    bottom: 0;
    content: ' ';
    position: absolute;
    right: 0;
    top: 0;
    width: 0.3rem;
    transition: background-color 0.2s linear;
  }
}

.label {
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.icon {
  @include utils.ml1;
  color: transparent;
  transition: color 0.2s linear;
}
.actionBtn {
  margin: 8px 16px 0px 16px;
}
