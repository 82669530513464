@use 'styles/colors';
@use 'styles/utils';
.wrapper {
  height: 40px;
  width: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  border: utils.$base-border-style;
  border-radius: 4px;
  cursor: help;
}

.attention {
  border-color: colors.$cardinal;
}
