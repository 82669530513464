@use 'styles/colors';
@use 'styles/utils';

.form {
  @include utils.p2;
  max-width: 500px;
}
.submitBtn {
  @include utils.ml1;
}

.requirements {
  @include utils.mt2;
}
