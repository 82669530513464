@use 'styles/colors';
@use 'styles/utils';

.container {
  @include utils.px3;
  overflow-y: scroll;
}

.mainContent {
  align-self: center;
  @include utils.py3;
  max-width: utils.$maxContentWidth;
  width: 100%;
}

.tab {
  padding: 10px 16px;
  font-size: 1.3rem;
  font-weight: bold;
  color: colors.$main-content-text !important;

  &:hover {
    color: colors.$interactive!important;
  }

  &:focus {
    outline: none;
  }

  &--active {
    color: colors.$interactive!important;
  }
}
