@use 'styles/colors';
@use 'styles/utils';

.container {
  background: colors.$gray-base;
  height: 100vh;
  width: 100vw;
}

.header {
  background-color: colors.$teal;
  @include utils.px2;
  height: 60px;
}

.logoLink {
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

.logo {
  height: 32px;
}

.childContainer {
  overflow: hidden;
}

.helpIcon {
  stroke: colors.$white;
  transition: stroke 0.2s;
  &:hover {
    stroke: colors.$white-highlight;
  }
}