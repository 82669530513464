@use 'styles/colors';
@use 'styles/utils';

$bannerHeight: 18rem;

.banner {
  width: 100%;
  height: $bannerHeight;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.12s linear;
  background-color: colors.$deep-aqua;
  @include utils.mx1;
  * {
    letter-spacing: 1;
  }
  &:hover {
    box-shadow: 0.1rem 0.1rem 0.5rem colors.$box-shadow;
  }
}
.textColumn {
  @include utils.mr2;
  @include utils.ml3;
  @include utils.py1;
  flex: 1;
  max-width: 650px;
}
.whiteText {
  color: colors.$white;
  white-space: pre-wrap;
}
.imageContainer {
  height: 100%;
  flex: 1;
  max-width: 36%;
  position: relative;
}
.imageWrapper {
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  opacity: 0;
  transition: opacity 2s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  border-radius: 3px;
}
.imageShown {
  opacity: 1;
}
.divider {
  border-color: #fafbfc;
  border-bottom-width: 0;
  border-top-width: 3px;
  margin: 0.8rem 0;
  width: 3rem;
}
