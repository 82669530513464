@use 'styles/colors';
@use 'styles/utils';

.card {
  @include utils.p1;
  background-color: colors.$white;
  border: utils.$base-border-style;
  border-radius: 4px;
  margin-top: 6px;
  width: 300px;
  transition: all 0.2s;

  &--hoverable {
    cursor: pointer;

    &:hover {
      background-color: colors.$livid-000;
      box-shadow: 0 0 4px 0 colors.$gray-300;
    }
  }

  &--selected {
    border-color: colors.$white-highlight!important;
    background-color: colors.$white-highlight !important;
    box-shadow: 0 0 4px 0 colors.$box-shadow !important;
  }
}
