@use 'styles/colors';
@use 'styles/utils';

.container {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  height: 49px;
  background-color: colors.$gray-100;
  border: utils.$base-border-style;
  border-radius: 4px 4px 0 0;
  width: 100%;
  padding: 0 4px 0 16px;
}

.contextItems {
  width: 100%;
  overflow: hidden;
  padding-right: 8px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  color: colors.$gray-600;
  margin-right: 16px;
  user-select: none;
}

.actionsArray {
  & > * {
    margin-right: 4px;
  }

  & :last-child {
    margin-right: 0;
  }
}

.hidden {
  display: none;
}
