@use 'styles/colors';
@use 'styles/utils';

.container {
  align-self: stretch;
  background-color: colors.$white;
  padding: 1.5rem 1.5rem 20% 1.5rem;
  max-width: 100%;
}

.icon {
  color: colors.$icon-display-only;
  @include utils.mb2;
}

.childrenContainer {
  @include utils.mt2;
}
.loadingText {
  white-space: normal;
  color: colors.$main-content-text;
  text-wrap: wrap !important;
}
