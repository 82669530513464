@use 'styles/utils';
@use 'styles/colors';

.p2 {
  @include utils.p2;
}

.loader {
  width: 100%;
  align-self: center;
}

.container {
  width: 100%;
  @include utils.pb1;
}