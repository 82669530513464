@use 'colors';

html {
  line-height: initial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

body {
  color: colors.$main-content-text;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

* {
  box-sizing: border-box;
}

:global(.react-hint__content) {
  background: colors.$gray-800;
  color: colors.$white;
}

:global(.react-hint--top:after) {
  border-top-color: colors.$gray-800;
}

:global(.react-hint--left:after) {
  border-left-color: colors.$gray-800;
}

:global(.react-hint--right:after) {
  border-right-color: colors.$gray-800;
}

:global(.react-hint--bottom:after) {
  border-bottom-color: colors.$gray-800;
}

a {
  color: colors.$interactive;
  text-decoration: none;
  transition: color 0.2s;

  &:active,
  &:visited {
    color: colors.$interactive-hover;
  }

  &:hover {
    color: colors.$interactive-hover;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
}

:global {
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
}

:global(#toast-container > div) {
  opacity: 0.95;
  box-shadow: 0 0 12px #000;
}

:global(.print-region) {
  opacity: 0;
}

@media print {
  :global(.print-region) {
    opacity: 1;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow-y: visible;
    z-index: 1000;
    background-color: white;

    @page {
      margin: 0mm !important;
      padding: 0mm !important;
    }

    input,
    button {
      display: none;
    }

    * {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  }

  body > *:not(:global(.print-region)) {
    display: none !important;
  }
}

:global(.toast-top-center) {
  top: 40px;
}
