@use 'styles/colors';
@use 'styles/utils';

.button {
  width: 100%;
}

.innerButton {
  flex: 1;
  justify-content: space-between;
}
.innerText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
