@use 'styles/colors';
@use 'styles/utils';

.holidayCalendarItem {
    height: 50px;
    padding: 20px;
    font-size: 1.1em;
    color: colors.$navy;
    font-weight: bold;
    background-color: colors.$gray;
    margin-bottom: 2px;
}