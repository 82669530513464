@use 'styles/colors';
@use 'styles/utils';

.header {
  align-items: center;
  @include utils.px2;
  border-bottom: utils.$base-border-style;
  background-color: colors.$gray-base;
  flex-shrink: 0;
  height: 50px;
  width: 100%;
}

.icon {
  @include utils.mr1;
  color: colors.$interactive;
  cursor: pointer;
  padding: 10px 0;
  height: 50px;
  width: 30px;

  &:hover {
    color: colors.$interactive-hover;
  }
}

.title {
  @include utils.pr2;
  color: colors.$navy;
  font-weight: bold;
  font-size: 1.8rem;
  letter-spacing: 0;
  line-height: 34px;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
