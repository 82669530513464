@use 'sass:math';

@use 'styles/colors';
@use 'styles/utils';

$swatchSize: 30px;
$swatchPadding: math.div(38px - $swatchSize, 2);

%transparent-box {
  background: linear-gradient(
    to top left,
    transparent 0%,
    transparent calc(50% - 0.8px),
    colors.$cardinal 50%,
    transparent calc(50% + 0.8px),
    transparent 100%
  );
  border: 1px solid colors.$cardinal;
}

.inputContainer {
  position: relative;
}

.input {
  padding-right: #{$swatchSize + 10px};
}

.swatch {
 border: utils.$base-border-style;
  border-radius: 2px;
  cursor: pointer;
  height: $swatchSize;
  position: absolute;
  right: $swatchPadding;
  top: $swatchPadding;
  width: $swatchSize;

  &--transparent {
    @extend %transparent-box;
  }
}

.pickerSection {
 border: utils.$base-border-style;
  border-radius: 4px;
  margin-top: 4px;
  max-width: 300px;
  padding: 6px;
  width: 100%;
}

%click-areas {
  background-size: cover !important;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
}

.pickingArea {
  @extend %click-areas;
  background: url(../../../../assets/images/color-picker/picker_mask.png) center;
  background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

.pickingAreaPoint {
  border: 1px solid white;
  border-radius: 50%;
  height: 12px;
  pointer-events: none;
  position: absolute;
  width: 12px;

  &:before {
    border: 1px solid colors.$gray-500;
    border-radius: 50%;
    content: '';
    height: 8px;
    position: absolute;
    width: 8px;
  }
}

.hue {
  @extend %click-areas;
  background: url(../../../../assets/images/color-picker/hue.png) center;
  background: linear-gradient(
    180deg,
    #f00 0%,
    #ff0 16.66%,
    #0f0 33.33%,
    #0ff 50%,
    #00f 66.66%,
    #f0f 83.33%,
    #f00 100%
  );
}

.huePoint {
  border: 2px solid white;
  height: 8px;
  left: 0;
  pointer-events: none;
  position: absolute;
}

.alpha {
  @extend %click-areas;
  background: url(../../../../assets/images/color-picker/alpha_mask.png);
}

.alphaPoint {
  border: 2px solid white;
  width: 8px;
  left: top;
  pointer-events: none;
  position: absolute;
}

.transparent {
  @extend %click-areas;
  @extend %transparent-box;
}

.suggestionContainer {
  margin: -4px -2px 0;
}

.suggestion {
  border: utils.$base-border-style;
  border-radius: 4px;
  cursor: pointer;
  margin: 4px 2px 0;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 0 3px 0 colors.$box-shadow;
  }
}
