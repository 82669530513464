@use 'styles/colors';
@use 'styles/utils';

.flexContainer {
  display: flex;
  align-items: stretch;
  align-content: flex-start;
}
.alignItems {
  &--stretch {
    align-items: stretch;
  }
  &--center {
    align-items: center;
  }
  &--flexStart {
    align-items: flex-start;
  }
  &--flexEnd {
    align-items: flex-end;
  }
  &--baseline {
    align-items: baseline;
  }
  &--initial {
    align-items: initial;
  }
  &--inherit {
    align-items: inherit;
  }
}
.flexDirection {
  &--row {
    flex-direction: row;
  }
  &--column {
    flex-direction: column;
  }
}
.justifyContent {
  &--flexStart {
    justify-content: flex-start;
  }
  &--flexEnd {
    justify-content: flex-end;
  }
  &--center {
    justify-content: center;
  }
  &--spaceBetween {
    justify-content: space-between;
  }
  &--spaceAround {
    justify-content: space-around;
  }
  &--spaceEvenly {
    justify-content: space-evenly;
  }
  &--initial {
    justify-content: initial;
  }
  &--inherit {
    justify-content: inherit;
  }
}
.keepHeight {
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  flex-basis: 0;
  height: 100%;
  flex-wrap: nowrap;

  &.flexDirection--row {
    flex-direction: row;
  }
}
.noShrink {
  flex-shrink: 0;
}
.growToContainer {
  flex-grow: 1;
}
.wrap {
  flex-wrap: wrap;
}
.scroll {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
