@use 'styles/colors';
@use 'styles/utils';
@mixin track() {
  background: colors.$grayVeryLight;
  border: utils.$base-border-style;
  border-radius: 4px;
  height: 12px;
}

@mixin thumb() {
  -webkit-appearance: none;
  background: colors.$blue-500;
  border-radius: 0.75rem;
  cursor: pointer;
  margin-top: -0.4rem;
  height: 1.5rem;
  width: 1.5rem;
}

:local {
  .slider {
    -webkit-appearance: none;
    display: block;
    height: 8px;
    margin: 1rem auto;
    width: 100%;

    &::-ms-track {
      width: 20rem;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    &::-webkit-slider-runnable-track {
      @include track();
    }

    &::-moz-range-track {
      @include track();
    }

    &::-ms-fill-lower {
      @include track();
    }

    &::-ms-fill-upper {
      @include track();
    }

    &::-webkit-slider-thumb {
      @include thumb();
    }

    &::-moz-range-thumb {
      @include thumb();
    }

    &::-ms-thumb {
      @include thumb();
    }

    &:not(.showFocus):focus {
      outline: none;
    }
  }
}