@use 'styles/colors';
@use 'styles/utils';

.container {
  margin-top: 1rem;

  &--noMargin {
    margin-top: 0;
  }

  input {
    flex-grow: 1;
  }
}

.labelContainer {
  display: flex;
  flex: 1;
  margin-bottom: 0.2rem;
  width: 100%;
  white-space: pre;
  align-items: center;
  justify-content: space-between;
}

.fullWidth {
  width: 100%;
}
