@use 'styles/colors';
@use 'styles/utils';

.wrapper {
  cursor: help;
}

.line {
  fill: none;
  stroke: colors.$blue-500;
  stroke-width: 4px;
}

.integral {
  fill: colors.$blue-200;
  stroke: none;
}

.skeleton-svg {
  @include utils.mr1;
}

.skeletonLine {
  fill: none;
  stroke: colors.$gray-400;
  stroke-width: 4px;
}

.skeletonIntegral {
  fill: colors.$gray-200;
  stroke: none;
}
.skeletonDashed {
  stroke: colors.$gray-400;
  stroke-width: 4px;
}
