@use 'styles/colors';
@use 'styles/utils';

.container {
  position: absolute;
  top: 0px;
  bottom: 0;
  transition: right 250ms ease;
  overflow: hidden;
  background-color: colors.$white;
  box-shadow: 0 0 0.4rem #000000;
  z-index: 901;
}

.overlay {
  z-index: 900;
  position: absolute;
  top: -200;
  left: -100;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
}

.fixed {
  position: fixed;
  top: utils.$HEADER_HEIGHT;
}
