@use 'styles/colors';
@use 'styles/utils';

.bodyContainer {
  width: 360px;
  overflow-y: auto;
}

.innerContainer {
  padding: 1.5rem;
  width: 100%;
}

.footer {
  align-self: stretch;
}

.btn {
  justify-content: center;
  align-items: center;
  padding: 14px;
  flex: 1;
  font-size: 16px;
  color: white;
  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
}
.clearFilters {
  background-color: colors.$caution-btn;
  color: colors.$caution-btn-text;
  border-bottom-left-radius: 4px;
}

.doneBtn {
  background-color: colors.$interactive;
  border-bottom-right-radius: 4px;
}
