@use 'styles/colors';
@use 'styles/utils';

.relativeContainer {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.staticWrapper {
  // border-right: 1px solid $border;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 0;
  z-index: 1;
}

.staticTable {
  height: 100%;

  td,

  th {
    border-right: utils.$base-border-style;
  }

  td {
    background-color: colors.$white;
  }
}

.scrollTable {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@media print {
  .relativeContainer,
  .scrollTable {
    display: block;
    height: initial;
    position: initial;
    width: 100%;
  }

  .staticWrapper {
    display: none;
  }
}
