@use 'styles/colors';

.wrapper {
  cursor: copy;

  &:active {
    cursor: wait;
    color: colors.$icon-active;
  }
}

.icon {
  display: block;
  flex-shrink: 0;
  color: colors.$icon-active;
}

.selected {
  color: colors.$icon-selected!important;
  cursor: pointer;

  .icon {
    color: colors.$icon-selected!important;
  }
  
  &:active {
    color: colors.$cardinal;
  }
}

.description {
  margin-left: 6px;
  font-size: 16px;
  color: colors.$helper-text;
  user-select: none;
}

.disabled {
  color: colors.$icon-disabled;
  cursor: not-allowed;
}

.locked {
  color: colors.$icon-disabled;
  cursor: not-allowed;
}
