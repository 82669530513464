@use 'styles/colors';
@use 'styles/utils';

$transitionTime: 0.2s;
$dayGridMaxWidth: 60rem;

.dayGridContainer {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 14px 0px 14px;
}

.headerContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.grid {
  overflow-y: auto;
  background-color: colors.$white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 85%;
  height: 100%;
  width: 100%;
  align-content: stretch;
  align-items: stretch;
  justify-content: space-between;
  transition: all $transitionTime linear;

  > div {
    min-width: 14.28%;
    max-width: 14.28%;
    box-sizing: border-box;
  }
}

.dayGrid {
  width: 100%;
  max-width: $dayGridMaxWidth;
  margin-bottom: 20px;
}

.maxHeightGrid {
  height: auto;
  min-height: 500px;
  border-bottom: utils.$base-border-style;
}
