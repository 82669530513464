@use 'styles/colors';
@use 'styles/utils';
.circle {
  margin-top: 2px;
  min-height: 10px;
  min-width: 10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 4px;
  display: flex;
  align-self: flex-start;
  background-color: colors.$lightGray;
}
.placeholder {
  width: 80%;
  height: 6px;
  background-color: colors.$lightGray;
  border-radius: 8px;
}
.day {
  min-height: 100px;
  padding: 6px 10px 6px 10px;
  border-top: utils.$base-border-style;
  overflow: hidden;

  &.showAllInMonthGrid {
    overflow: visible;
    min-height: auto;
  }
  &:hover {
    background-color: colors.$graySuperLight;
    cursor: pointer;
  }
  &.dayRightBorder {
    border-right: utils.$base-border-style;
  }
  &.selectedDay {
    background-color: colors.$white-highlight;
  }
}
.dayOutsidePeriod {
  color: colors.$gray-200;
}
.dayNumber {
  font-size: 1em;
  &.isSelected {
    font-weight: bold;
    color: colors.$interactive;
  }
}
.week {
  overflow-y: auto;
  &:hover {
    background-color: initial;
    cursor: initial;
  }
}
@media print {
  .today {
    display: none;
  }
}
.today {
  color: colors.$interactive;
  font-size: 0.8em;
  font-weight: bold;
}
.lockedWeekDay {
  margin-top: 2em;
  color: colors.$deep-gray;
}