@use 'styles/colors';

.label {
  color: colors.$helper-text;
  font-weight: 600;
  margin-bottom: 0.2rem;

  &--noMargin {
    margin-bottom: 0;
  }
}

.error {
  color: colors.$error;
}

.disabled {
  color: colors.$disabled;
}
