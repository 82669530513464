@use 'styles/colors';

.group {
  margin: 0;
}

.labelText {
  font-weight: 400;
  color: colors.$gray-600;
  font-size: 14px;
}

.time {
  font-weight: 600;
  color: colors.$blue-900;
  font-size: 16px;
}

.finished {
  text-decoration: line-through;
}

.arrow {
  color: colors.$tangerine;
  margin: 8px;
}
