@use 'styles/colors';
@use 'styles/utils';

.scrollContainer {
  background-color: colors.$white;
  overflow-y: auto;
  width: 100%;
}

.formContainer {
  @include utils.p2;
  max-width: 500px;
  width: 100%;
  align-self: center;
}

.footerBtn {
  @include utils.ml1;
}

.actionBtn {
  @include utils.mb1;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }
}
