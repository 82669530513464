@use 'styles/colors';
@use 'styles/utils';

.todayBtn {
  color: colors.$interactive;
  @include utils.mx1;
  font-weight: bold;
  font-size: 1.2em;
  &:hover {
    cursor: pointer;
    color: colors.$interactive-hover;
  }
}
.pagerIcon {
  color: colors.$interactive;

  &:hover {
    color: colors.$interactive-hover;
    cursor: pointer;
  }
}
.disabledPager {
  color: colors.$deep-gray;
  pointer-events: none;
  &:hover {
    color: colors.$deep-gray;
  }
  cursor: default;
}
.viewBtnGroup {
  @include utils.mr1;
}
.hideListBtn {
  margin-left: 20px;
}
.filterContainer {
  @include utils.ml1;
}
.printBtn {
  @include utils.mr1;
}
.filterBtn {
  @include utils.mx1;
}
