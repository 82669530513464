@use 'styles/colors';
@use 'styles/utils';

.tag {
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  color: colors.$deep-aqua;
  position: relative;
  padding: 0px 4px;
  padding-right: 28px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  margin-bottom: 2px;
  margin-top: 2px;
  transition: color 0.3s linear;
  &:hover {
    cursor: pointer;
    &:before {
      background: colors.$deep-aqua;
    }
  }
}

.tag:before {
  background: colors.$pale-aqua;
  width: 5px;
  height: 5px;
  content: '';
  display: flex;
  border-radius: 10px;
  margin-right: 4px;
  margin-left: 2px;
  transition: background 0.3s linear;
}

.tag:after {
  content: '';
  border-radius: 5px;
  display: flex;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.tag .arrow {
  border: 10px solid;
  border-color: #fff #fff #fff transparent;
  z-index: 10;
  position: absolute;
  right: 0;
}
