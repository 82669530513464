@use 'styles/colors';
@use 'styles/utils';

.container {
  background-color: colors.$gray-base;
  border: utils.$base-border-style;
  padding: 4px 8px;
  @include utils.mb1;
  border-radius: 4px;
  align-self: stretch;
  flex-shrink: 0;
  &.divider {
    padding: 2px 8px;
    .title {
      font-size: 1em;
    }
  }
}
.title {
  font-size: 1.5em;
  font-style: normal;
  font-weight: 700;
  color: colors.$livid-700;
}
.subtle {
  font-size: 20px;
  font-weight: 700;
  color: colors.$gray-600;
  @include utils.p1;
}
.joined {
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
}
