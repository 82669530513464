@use 'styles/colors';
@use 'styles/utils';

.item {
  @include utils.px2;
  @include utils.py1;
  border: utils.$base-border-style;
  border-radius: 4px;
  margin-bottom: 2px;
  transition: 0.2s linear all;
}

.joined {
  margin-bottom: 0px;
  border-bottom: none;
  border-radius: 0px;
  &:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    margin-top: 2px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: utils.$base-border-style;
  }
}
.clickable {
  cursor: pointer;
  &:hover {
    background-color: colors.$livid-000;
  }
}
