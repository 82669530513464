@use 'styles/colors';

.wrapper {
  display: inline-flex;
}

.linkText {
  text-decoration: underline;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
  &:active {
    text-decoration: none;
  }
}

.default {
  color: colors.$gray-600;

  &:hover {
    color: colors.$tangerine;
    cursor: pointer;
  }
  &:active {
    cursor: pointer;
  }
}

/**************************
    Link Type Styles
**************************/
.additional {
  color: colors.$gray-600;

  &:hover {
    color: colors.$tangerine;
  }
}

.primary {
  color: colors.$interactive;

  &:hover {
    color: colors.$interactive-hover;
  }
}

.attention {
  color: colors.$cardinal;

  &:hover {
    color: colors.$deep-cardinal;
  }
}

/**************************
    Cursor Type Styles
**************************/

.wait {
  &:hover {
    cursor: pointer;
  }
  &:active {
    cursor: wait;
  }
}

.modal {
  &:hover {
    cursor: pointer;
  }
  &:active {
    cursor: wait;
  }
}

.navigate {
  &:hover {
    cursor: pointer;
  }
  &:active {
    cursor: alias;
  }
}
