@use "sass:color";
/************************* LL BRAND UPDATE COLORS *************************/
// Base Colors
$white: #ffffff;
$pale-gray: #f1f3f4;
$gray: #d2d9db;
$deep-gray: #4f6475;
$charcoal: #2f4149;
$navy: #013a53;
$teal: #00617d;
$deep-aqua: #23788b;
$pale-aqua: #e9f2f3;
$pale-emerald: #ecf5ec;
$emerald: #208345;
$deep-emerald: #135e2f;
$lime: #90d432;
$pale-tangerine: #fff6e7;
$tangerine: #fea511;
$cardinal: #cc4039;
$deep-cardinal: #952c26;
$pale-cardinal: #ffe9e8;
$branded-gray: #323638;

// UI Styles
$gray-base: $pale-gray;
$border-color: $gray;
$box-shadow: rgba($charcoal, 0.25);
// Text Colors
$main-content-text: $navy;
$helper-text: $deep-gray;

// Interaction Colors
$white-highlight: $pale-aqua;
$disabled: $gray;
$interactive: $deep-aqua;
$interactive-hover: $navy;

// Icon Colors
$icon-display-only: $navy;
$icon-active: $deep-gray;
$icon-hover: $gray;
$icon-disabled: $gray;
$icon-selected: $deep-aqua;

// Button Colors
$disabled-btn: $gray;
$disabled-btn-text: $deep-gray;
$disabled-btn-text-hover: $deep-cardinal;

$primary-btn: $deep-aqua;
$primary-btn-hover: $navy;

$required-btn: $emerald;
$required-btn-hover: $deep-emerald;

$caution-btn: $pale-gray;
$caution-btn-text: $deep-gray;
$caution-btn-hover: $gray;
$caution-btn-hover-border: $navy;
$caution-btn-hover-text: $navy;

$additional-btn: $white;
$additional-btn-text: $deep-gray;
$additional-btn-hover: $pale-gray;
$additional-btn-border: $gray;
$additional-btn-hover-border: $navy;

$permanent-btn: $cardinal;
$permanent-btn-text: $white;
$permanent-btn-hover: $deep-cardinal;

$significant-btn: $pale-emerald;
$significant-btn-border: $emerald;
$significant-btn-text: $navy;
$significant-btn-hover: $emerald;

$selected-btn: $pale-aqua;
$selected-btn-text: $navy;
$selected-btn-hover: $pale-gray;
$selected-btn-border: $navy;

$unselected-btn: $white;
$unselected-btn-text: $deep-gray;
$unselected-btn-border: $gray;
$unselected-btn-hover: $pale-gray;
$unselected-btn-border-hover: $navy;
$unselected-btn-text-hover: $navy;

$revoked-btn: $pale-tangerine;
$revoked-btn-border: $tangerine;
$revoked-btn-text: $navy;

$open: $tangerine;
$pending: $tangerine;
$in-progress: $emerald;
$approved: $emerald;
$denied: $cardinal;
$cancelled: $gray;
$completed: $deep-aqua;

/************************* PRODUCTION COLORS *************************/
$blue-900: #00273e;
$blue-800: #003f6a;
$blue-700: #005696;
$blue-600: #016ec1;
$blue-500: #0186ed;
$blue-400: #309df1;
$blue-300: #63b6f4;
$blue-200: #8ecbf8;
$blue-100: #bde2fb;
$blue-000: #ecf9ff;

$livid-900: #182833;
$livid-800: #1f394c;
$livid-700: #2d506b;
$livid-600: #3a6586;
$livid-500: #4679a2;
$livid-400: #739dbe;
$livid-300: #accbe2;
$livid-200: #c5dced;
$livid-100: #e3f1fa;
$livid-000: #f5fafd;

$gray-900: #24292d;
$gray-800: #2f363d;
$gray-700: #434d56;
$gray-600: #576069;
$gray-500: #69737d;
$gray-400: #949da5;
$gray-300: #d1d5d9;
$gray-200: #e0e4e8;
$gray-100: #f6f8fa;
$gray-000: #fafbfc;

$green-900: #33691e;
$green-800: #4e8627;
$green-700: #69a430;
$green-600: #83c139;
$green-500: #9ede42;
$green-400: #b0e463;
$green-300: #c2ea84;
$green-200: #d5efa5;
$green-100: #e7f5c6;
$green-000: #f9fbe7;

$yellow-900: #b97000;
$yellow-800: #c68b17;
$yellow-700: #d3a517;
$yellow-800: #e0c017;
$yellow-500: #edda00;
$yellow-400: #f1e12e;
$yellow-300: #f4e85c;
$yellow-200: #f8ef8b;
$yellow-100: #fbf6b9;
$yellow-000: #fffde7;

$red-000: #fff3f5;
$red-100: #fad2d0;
$red-200: #f5b0ab;
$red-300: #f18f86;
$red-400: #ec6d61;
$red-500: #e74c3c;
$red-600: #cf3f34;
$red-700: #b7322d;
$red-800: #9e2525;
$red-900: #86181d;

/************************* DEPRECATED *************************/

/* Branding */
$primary: $green-500;
$primary-d: $green-600;
$primary-d-2: $green-800;
$primary-l: $green-400;

$secondary: $blue-500;
$secondary-d: $blue-600;
$secondary-l: $blue-400;

/* Canvas */
$canvas-5: $blue-900;
$canvas-4: $blue-800;
$canvas-2: $gray-200;
$canvas-1: $gray-000;

/* Borders */
$border-regular: $gray-200;
$border-reverse: $gray-600;

/* Text */
$text: $gray-600;
$text-reverse: $gray-200;

/* Gray Scale */
$white: white;
$black: black;
$graySuperLight: $gray-000;
$grayVeryLight: $gray-100;
$grayMediumLight: $gray-300;
$grayMedium: $gray-300;
$grayMediumDark: $gray-400;
$grayDark: $gray-500;
$grayVeryDark: $gray-600;
$darkGray: $gray-700;
$black: $gray-900;
$midDarkGray: $gray-500;
$lightGray: $gray-200;

/* Border Colors */
$borderRegular: $gray-200;
$border: $gray-200;

/* System Information */
$error: $cardinal;
$error-d: $red-700;
$error-d-2: $red-800;
$error-l: $red-300;

$success: $green-500;
$success-d: $green-600;
$success-d-2: $green-800;
$success-l: $green-300;
$success-l-2: $green-200;

$warning: $yellow-500;
$warning-l: $yellow-300;
$warning-d: $tangerine;
$warning-d-2: $yellow-800;

$info: $blue-400;
$info-d: $blue-500;
$info-d-2: $blue-700;
$info-l: $blue-200;

/* Activity Colors */

/* Meals */
$mealColor: #cddc39;
$mealTextColor: #5a7904;
$mealBorderColor: $mealColor;
$mealBgColor: color.adjust($mealColor, $lightness: 45%);

/* Personal */
$personalColor: #009688;
$personalBorderColor: $personalColor;
$personalBgColor: color.adjust($personalColor, $lightness: 35%);

/* Snack */
$snackColor: #4caf50;
$snackBorderColor: $snackColor;
$snackBgColor: color.adjust($snackColor, $lightness: 45%);

/* Work Request Colors */
$openColor: $success;
$inProgressColor: $warning;
$closedColor: $grayDark;
$canceledColor: $grayDark;

/* Transportation Colors */
$transPendingColor: $warning;
$transPendingDark: $warning-d-2;
$transPendingLight: $warning-l;

$transApprovedColor: $success;
$transApprovedDark: $success-d-2;
$transApprovedLight: $success-l;

$transDeniedColor: $error;
$transDeniedDark: $error-d;
$transDeniedLight: $error-l;

$transCancelledColor: $grayDark;
$transCancelledDark: $grayVeryDark;
$transCancelledLight: $grayMedium;

$transCompletedColor: $info;
$transCompletedDark: $info-d-2;
$transCompletedLight: $info-l;

/* Other colors */
$midnightBlue: #04314e;
$darkOrange: #dca000;
$mediumOrange: #ffa000;
$darkRed: #e7564b;
$lightRed: #fbe9e7;
$midViolet: #3c4259;
$greenBrand: #8bc640;
$deleteText: $darkRed;
$blueBrand: $blue-500;
$greenBrandDark: #77b12e;
$pccGreenFaded: #f2f9e0;
$pccGreen: #97cc04;
$veryOpaqueWhite: rgba(255, 255, 255, 0.2);
$opaqueWhite: rgba(255, 255, 255, 0.85);
