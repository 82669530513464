@use 'styles/utils';

.infoMessage {
  @include utils.mb1;
  padding-top: 0;
}

.requirements {
  @include utils.mt2;
}
