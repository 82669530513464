@use 'styles/colors';
@use 'styles/utils';

.circle {
  border-radius: 50%;
  background-color: colors.$blue-500;
  display: inline-block;
  @include utils.mr1;
}
.black {
  background-color: #000;
}
// Work Requests
.open {
  background-color: colors.$open;
}
.in_progress {
  background-color: colors.$in_progress;
}

// Work Requests
.pending {
  background-color: colors.$pending;
}

.approved {
  background-color: colors.$approved;
}

// Both and equal
.cancelled {
  background-color: colors.$cancelled;
}

.completed {
  background-color: colors.$completed;
}
.denied {
  background-color: colors.$denied;
}
