@use 'styles/colors';
@use 'styles/utils';

.container {
  background-color: colors.$white;
  border: utils.$base-border-style;
  border-radius: 4px;
  font-size: 1em;
  font-weight: 600;
  height: 39px;
  letter-spacing: 0;
  padding: 4px;
  text-align: center;
  white-space: nowrap;

  &--mini {
    border-radius: 3px;
    font-size: 0.78em;
    height: 24px;
    padding: 0px 2px;

    .icon {
      margin-right: 6px;
    }

    .child {
      height: 18px;
      padding: 0;

      &--selected {
        box-shadow: 0 0 2px 0 rgba(colors.$emerald, 0.5);
      }

      &--iconOnly {
        width: 18px;
        padding: 0;
      }
    }
  }
}

.child {
  border: 2px solid transparent;
  border-radius: 2px;
  color: colors.$deep-gray;
  cursor: pointer;
  height: 29px;
  margin-right: 4px;
  padding: 0 10px;
  transition: all 0.2s linear;

  &:hover {
    color: colors.$main-content-text;
    border: 2px solid colors.$navy!important;
    background-color: colors.$gray-base;
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.6;
    pointer-events: none;
  }

  &--selected {
    color: colors.$main-content-text;
    box-shadow: 0 0 2px 0 colors.$interactive;
    border: 2px solid colors.$interactive;
    background-color: colors.$white-highlight;

    &:hover {
      border-color: transparent;
    }
  }

  &--iconOnly {
    width: 39px;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.icon {
  margin-right: 8px;
}
