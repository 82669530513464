@use 'styles/colors';
@use 'styles/utils';

.container {
  margin: 32 8px;
  padding: 0 8px;
  flex: 1;
  border-top: utils.$base-border-style;
  overflow-y: auto;
}
.headerText {
  font-size: 14px;
  font-weight: 600;
  color: colors.$blue-900;
  margin-top: 32px;
  margin-bottom: 8px;
}
.badge {
  display: block;
  height: 24px;
  max-width: 121px;
  margin-bottom: 4;
  padding: 3px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  transition: 0.2s linear all;
  cursor: pointer;

  background-color: colors.$white;
  border: utils.$base-border-style;
  color: colors.$interactive;
  transition: 0.2s linear box-shadow;
  &:hover {
    box-shadow: 0 0 4px 0 colors.$interactive;
  }
  &--selected {
    border: 1px solid colors.$interactive;
    background-color: colors.$interactive;
    color:colors.$white;
  }
}
.countText {
  font-size: 12;
  font-weight: 600;
  color: colors.$helper-text;
  transition: 0.2s all linear;
  &--selected {
    color: colors.$main-content-text;
    font-weight: bold;
    font-size: 14;
  }
}
