@use 'styles/colors';
@use 'styles/utils';

.inlineQuickFilters {
  overflow-x: auto;
  padding: calc(utils.$space / 2) 0;
  // prevents the list from jumping when no options match
  min-height: 70px;
}

.noScroll {
  overflow: unset;
}

.removeAll {
  height: 39px;
  width: 39px;
  min-width: 39px;
  max-width: 39px;
  margin-right: 4px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: colors.$gray-100;
  border: utils.$base-border-style;
  color: colors.$gray-600;
  border-radius: 100px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    border-color: colors.$cardinal;
    background-color: colors.$red-000;
    color: colors.$cardinal;
  }
}

.scrollShadowLeft {
  position: absolute;
  background-color: red;
  width: 39px;
  height: 39px;
  left: -39px;
  box-shadow: utils.$shadow;
  z-index: 10;
}

.scrollShadowRight {
  position: absolute;
  background-color: red;
  width: 39px;
  height: 39px;
  right: -39px;
  box-shadow: utils.$shadow;
  z-index: 10;
}

.filterGroup {
  position: relative;
  z-index: 0;
}

.hidden {
  display: none;
}

.collapsed {
  min-height: auto;
}

.collapsedBtn {
  padding-top: 0;
}
