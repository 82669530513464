@use 'styles/colors';
@use 'styles/utils';

.tab {
  @include utils.mr1;
  @include utils.px2;
  align-items: center;
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  color: colors.$interactive!important;
  cursor: pointer;
  display: flex;
  font-size: 1.15rem;
  height: 50px;
  padding-top: 0.3rem;

  &:focus {
    outline: none;
  }
}

.activeTab {
  border-bottom: 4px solid colors.$interactive !important;
}
