@use 'styles/colors';
@use 'styles/utils';

$border-radius: 5px;
$info-height: 75vh;

.container {
  @include utils.m2;
}

.infoContainer {
  background-color: colors.$white;
  border-radius: $border-radius;
  @include utils.p3;
  height: 75vh;
  max-width: 800px;
  overflow-y: auto;
  width: 75vw;

  &--mini {
    max-width: 400px;
    height: auto;
  }
}

.title {
  @include utils.pb2;
}

.link {
  text-decoration: underline;
  color: colors.$blue-500;
  transition: color 0.2s;

  &:hover {
    color: colors.$blue-600;
  }
}
