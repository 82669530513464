@use 'styles/_colors';
@use 'styles/utils';

.tile {
  width: 256px;
  height: 256px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  background-color: colors.$emerald;
  @include utils.p1;
  color: colors.$white;
  .backdrop {
    position: absolute;
    height: 100%;
    width: 96%;
    left: 2%;
    z-index: -1;
    background-color: colors.$deep-aqua;
    transition: all 0.15s linear;
    border-radius: 4px;
    top: 8px;
  }
  &:hover {
    .backdrop {
      transform: translateY(1px) translateX(3%);
    }
  }

  .previewImageContainer {
    position: absolute;
    bottom: 8px;
    left: 8px;
    overflow: hidden;
    width: 128px;
    height: 128px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .linkContainer {
    position: absolute;
    bottom: 8px;
    right: 8px;
  }

  .titleIcon {
    flex-shrink: 0;
  }

  .titleContainer {
    align-items: center;
  }
  
  .tileTitle {
    color: colors.$white;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    white-space: normal;
    word-wrap: break-word;
    @include utils.ml1;
  }
}

.solid {
  background-color: colors.$emerald;
}