@use "styles/colors";
@use "styles/utils";

$border-radius: 16px;
$form-height: 90vh;

.layout {
  background: colors.$gray-base;
  height: 100%;
  max-height: $form-height;
  width: 100vw;
}

.container {
  @include utils.m2;
  box-shadow: 0 0 10px 0 colors.$box-shadow;
  border-radius: $border-radius;
  margin-top: 32px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.scrollable {
  max-height: $form-height;
  width: 100%;
  overflow-y: auto;
}

.formContainer {
  background-color: colors.$white;
  border-bottom-left-radius: $border-radius;
  border-top-left-radius: $border-radius;
  @include utils.p3;
  height: 100%;
  width: 100%;
  padding: 64px;
  overflow-x: hidden;
}

.imageContainer {
  position: relative;
}

.image {
  border-bottom-right-radius: $border-radius;
  border-top-right-radius: $border-radius;
  max-height: $form-height;
}