.groupChild {
  flex: 1;
  margin: 0 4px;

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-left: 0;
  }
}

.fullWidth {
  flex: 1;
}
