@use 'sass:math';
@use 'styles/colors';

/* Base Spacing Unit */
$space: 8px;
$maxContentWidth: 960px;

$shadow: 0 0 6px rgba(#00273e, 0.25);
$base-border-width: 2px;
$base-border-style: $base-border-width solid colors.$border-color;
$active-border-style: $base-border-width solid colors.$interactive;
$error-border-style: $base-border-width solid colors.$error;
$HEADER_HEIGHT: 60px;
$RESIDENT_HEIGHT: 70px;
$SIDEBAR_HEIGHT: 56px;
$SIDEBAR_OPENED_WIDTH: 220px;

/* Margin */
@mixin m0 {
  margin: 0;
}

@mixin mt0 {
  margin-top: 0;
}

@mixin mr0 {
  margin-right: 0;
}

@mixin mb0 {
  margin-bottom: 0;
}

@mixin ml0 {
  margin-left: 0;
}

@mixin mx0 {
  margin-left: 0;
  margin-right: 0;
}

@mixin my0 {
  margin-top: 0;
  margin-bottom: 0;
}

@mixin m1 {
  margin: $space;
}

@mixin mt1 {
  margin-top: $space;
}

@mixin mr1 {
  margin-right: $space;
}

@mixin mb1 {
  margin-bottom: $space;
}

@mixin ml1 {
  margin-left: $space;
}

@mixin mxAuto {
  margin-left: auto;
  margin-right: auto;
}

@mixin mx1 {
  margin-left: $space;
  margin-right: $space;
}

@mixin my1 {
  margin-top: $space;
  margin-bottom: $space;
}

@mixin m2 {
  margin: $space * 2;
}

@mixin mt2 {
  margin-top: $space * 2;
}

@mixin mr2 {
  margin-right: $space * 2;
}

@mixin mb2 {
  margin-bottom: $space * 2;
}

@mixin ml2 {
  margin-left: $space * 2;
}

@mixin mx2 {
  margin-left: $space * 2;
  margin-right: $space * 2;
}

@mixin my2 {
  margin-top: $space * 2;
  margin-bottom: $space * 2;
}

@mixin m3 {
  margin: $space * 4;
}

@mixin mt3 {
  margin-top: $space * 4;
}

@mixin mr3 {
  margin-right: $space * 4;
}

@mixin mb3 {
  margin-bottom: $space * 4;
}

@mixin ml3 {
  margin-left: $space * 4;
}

@mixin mx3 {
  margin-left: $space * 4;
  margin-right: $space * 4;
}

@mixin my3 {
  margin-top: $space * 4;
  margin-bottom: $space * 4;
}

@mixin m4 {
  margin: $space * 8;
}

@mixin mt4 {
  margin-top: $space * 8;
}

@mixin mr4 {
  margin-right: $space * 8;
}

@mixin mb4 {
  margin-bottom: $space * 8;
}

@mixin ml4 {
  margin-left: $space * 8;
}

@mixin mx4 {
  margin-left: $space * 8;
  margin-right: $space * 8;
}

@mixin my4 {
  margin-top: $space * 8;
  margin-bottom: $space * 8;
}

/* Padding */
@mixin p0 {
  padding: 0;
}

@mixin pt0 {
  padding-top: 0;
}

@mixin pr0 {
  padding-right: 0;
}

@mixin pb0 {
  padding-bottom: 0;
}

@mixin pl0 {
  padding-left: 0;
}

@mixin px0 {
  padding-left: 0;
  padding-right: 0;
}

@mixin py0 {
  padding-top: 0;
  padding-bottom: 0;
}

@mixin p1 {
  padding: $space;
}

@mixin pt1 {
  padding-top: $space;
}

@mixin pr1 {
  padding-right: $space;
}

@mixin pb1 {
  padding-bottom: $space;
}

@mixin pl1 {
  padding-left: $space;
}

@mixin py1 {
  padding-top: $space;
  padding-bottom: $space;
}

@mixin px1 {
  padding-left: $space;
  padding-right: $space;
}

@mixin p2 {
  padding: $space * 2;
}

@mixin pt2 {
  padding-top: $space * 2;
}

@mixin pr2 {
  padding-right: $space * 2;
}

@mixin pb2 {
  padding-bottom: $space * 2;
}

@mixin pl2 {
  padding-left: $space * 2;
}

@mixin py2 {
  padding-top: $space * 2;
  padding-bottom: $space * 2;
}

@mixin px2 {
  padding-left: $space * 2;
  padding-right: $space * 2;
}

@mixin p3 {
  padding: $space * 4;
}

@mixin pt3 {
  padding-top: $space * 4;
}

@mixin pr3 {
  padding-right: $space * 4;
}

@mixin pb3 {
  padding-bottom: $space * 4;
}

@mixin pl3 {
  padding-left: $space * 4;
}

@mixin py3 {
  padding-top: $space * 4;
  padding-bottom: $space * 4;
}

@mixin px3 {
  padding-left: $space * 4;
  padding-right: $space * 4;
}

@mixin p4 {
  padding: $space * 8;
}

@mixin pt4 {
  padding-top: $space * 8;
}

@mixin pr4 {
  padding-right: $space * 8;
}

@mixin pb4 {
  padding-bottom: $space * 8;
}

@mixin pl4 {
  padding-left: $space * 8;
}

@mixin py4 {
  padding-top: $space * 8;
  padding-bottom: $space * 8;
}

@mixin px4 {
  padding-left: $space * 8;
  padding-right: $space * 8;
}

@mixin normalBorder {
  border: $base-border-style;
}

@mixin errorBorder {
  border: 1px solid colors.$error;
}

@mixin borderTop {
  border-top: utils.$base-border-style;
}

$minWidth: 400;
$maxWidth: 1440;

@mixin cancelledBackground {
  background: colors.$disabled;
  background: repeating-linear-gradient(45deg,
      colors.$disabled,
      colors.$disabled 1px,
      colors.$white 1px,
      colors.$white 5px);
}

@keyframes bounce {

  0%,
  50%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-3px);
  }

  75% {
    transform: translateX(-3px);
  }
}

@function variableSize($min, $max) {
  @return calc(#{$min}px + ((100vw - #{$minWidth}px) / #{(
 math.div($maxWidth - $minWidth, $max - $min))
  }));
}