@use 'styles/colors';
@use 'styles/utils';

.container {
  @include utils.mb1;
  margin-right: -6px;
}

.imageContainer {
  background-position: center;
  background-size: cover;
  margin: 3px 6px 3px 0;
  position: relative;
}

.removeContainer {
  background-color: colors.$gray-000;
  border-radius: 50%;
  cursor: pointer;
  padding: 3px;
  position: absolute;
  right: 4px;
  top: 4px;
  transition: box-shadow 0.2s linear;

  &:hover {
    box-shadow: 1px 3px 5px 0 colors.$box-shadow;
  }
}
