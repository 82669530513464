@use 'styles/colors';
@use 'styles/utils';

.badge {
  background-color: colors.$gray-base;
  border: utils.$base-border-style;
  border-radius: 4px;
  color: colors.$deep-gray;
  font-size: 0.9em;
  margin-top: 2px;
  padding: 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: unset;
  &.hoverable:hover {
    cursor: pointer;
    .chevron {
      animation-name: bounce;
    }
  }
  &.alert {
    background-color: colors.$cardinal;
    color: colors.$white;
    border: 1px solid colors.$deep-cardinal;
    &.hoverable:hover {
      background-color: colors.$deep-cardinal;
      cursor: pointer;
    }
    .chevron {
      color: colors.$cardinal;
    }
  }
  &.complete {
    background-color: colors.$navy;
    color: colors.$white;
    border: 1px solid colors.$navy;
  }
  &.action {
    background-color: colors.$interactive;
    color: colors.$interactive;
    border: 1px solid colors.$interactive;
    &.hoverable:hover {
      border: 1px solid colors.$blue-200;
    }
    .chevron {
      color: colors.$interactive;
    }
  }

  &.success {
    background-color: colors.$green-300;
    color: colors.$green-900;
    border: 1px solid colors.$green-400;
  }
  &.warning {
    background-color: colors.$yellow-300;
    color: colors.$yellow-900;
    border: 1px solid colors.$yellow-500;
  }
  &.secondary {
    background-color: colors.$white;
    border: utils.$base-border-style;
    color: colors.$interactive;
    transition: 0.2s linear box-shadow;
    &.hoverable:hover {
      box-shadow: 0 0 4px 0 colors.$interactive;
    }
  }
}

.chevron {
  margin-left: 0px;
  margin-right: -6px;
  color: colors.$gray-500;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
