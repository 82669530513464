@use 'styles/colors';
@use 'styles/utils';

.container {
  width: 100%;
  max-width: 1100px;
}

.hidden {
  display: none;
}

.activeTab {
  color: colors.$interactive;
  cursor: auto;

  &:hover {
    text-decoration: none;
  }
}

.listWrapper {
  width: 100%;
  z-index: 2;

  > :not(:last-child) {
    border-radius: 0px;
  }

  > :last-child {
    border-radius: 0px 0px 4px 4px;
  }
}

.previousWrapper {
  width: 100%;
  position: relative;
}

.expandButton {
  width: 100%;
  min-height: 39px;
  padding: 8px;
  border: utils.$base-border-style;
  border-width: 0px 1px 1px 1px;
  text-decoration: underline;
  font-weight: 600;
  color: colors.$gray-600;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: colors.$blue-500;
  }

  &:active {
    text-decoration: none;
    cursor: wait;
  }
}

.moreWrapper {
  width: 100%;
  position: relative;
}

.shadow {
  background-color: colors.$white;
  width: 97%;
  height: 16px;
  box-shadow: 0 0 6px colors.$box-shadow;
  z-index: 1;
  position: absolute;
  top: -16px;
}

.moreButton {
  z-index: 0;
  width: 95%;
  background-color: colors.$interactive;
  min-height: 39px;
  padding: 8px;
  text-decoration: underline;
  font-weight: 600;
  color: colors.$gray-600;
  cursor: pointer;
  border-radius: 0px 0px 4px 4px;
  border: utils.$base-border-style;
  border-width: 0px 1px 1px 1px;
  user-select: none;

  &:hover {
    color: colors.$blue-500;
    box-shadow: utils.$shadow;
    background-color: colors.$blue-100;
  }

  &:active {
    font-size: 15px;
    text-decoration: none;
    background-color: colors.$blue-100;
    box-shadow: none;
    cursor: progress;
  }
}
