@use "styles/colors";
@use "styles/utils";

$border-radius: 16px;
$form-height: 90vh;

.layout {
  background: colors.$gray-base;
  height: 100%;
  max-height: $form-height;
  width: 100vw;
}

.container {
  @include utils.m2;
  box-shadow: utils.$shadow;
  border-radius: $border-radius;
  margin-top: 32px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.scrollable {
  max-height: $form-height;
  width: 100%;
  overflow-y: auto;
}

.formContainer {
  background-color: colors.$white;
  border-bottom-left-radius: $border-radius;
  border-top-left-radius: $border-radius;
  @include utils.p3;
  height: 100%;
  width: 100%;
  padding: 64px;
  overflow-x: hidden;

  .subtitle {
    color: colors.$deep_gray;
    margin-top: 16px;
    font-weight: 700;
    max-width: 790px;
  }
}

.alertContainer {
  margin-top: 32px;

  .alertIconContainer {
    background-color: colors.$cardinal;
    height: 100%;
    padding: 26px 11px;
    border-radius: 4px;
    z-index: 2;

    .alertIcon {
      color: colors.$white;
    }
  }

  .alertTextContainer {
    border-radius: 0 4px 4px 0;
    margin-left: -2px;
    border-top: 1px solid colors.$gray;
    border-right: 1px solid colors.$gray;
    border-bottom: 1px solid colors.$gray;
    padding: 16px;
    color: colors.$deep-gray;
    font-style: italic;
    font-size: 22px;
    max-width: 860px;
    padding-right: 100px;
  }
}

.imageContainer {
  position: relative;
}

.image {
  border-bottom-right-radius: $border-radius;
  border-top-right-radius: $border-radius;
  max-height: $form-height;
}

@media screen and (max-width: 1150px) {
  .imageContainer {
    display: none;
  }
}