@use 'styles/colors';
@use 'styles/utils';

.link {
  color: colors.$interactive;
  font-weight: 600;
  text-decoration: underline;
  transition: color 0.2s;
  &:hover {
    color: colors.$interactive-hover;
  }
}

.label {
  color: colors.$helper-text;
  font-weight: 600;
  margin-right: utils.$space;
}