@use 'styles/colors';
@use 'styles/utils';

.tab {
  @include utils.px3;
  @include utils.py1;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  font-size: 16px;
  color: colors.$gray_600;
  border: 1px solid transparent;
  border-bottom: 1px solid colors.$gray_300;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.1s linear;
  &:hover {
    color: colors.$blue_500;
  }
  &.active {
    color: colors.$blue_500;
    border: 1px solid colors.$gray_300;
    border-bottom-color: transparent;
  }
}
.divider {
  border-bottom: 1px solid colors.$gray_300;
}

.subtleTab {
  padding-bottom: 6px;
  color: colors.$main-content-text;
  font-weight: bold;
  font-size: 16px;
  @include utils.mt1;
  @include utils.mx1;
  &:hover {
    padding-bottom: 4px;
    border-bottom: 2px solid colors.$interactive;
    cursor: pointer;
  }
}

.selectedSubtleTab {
  padding-bottom: 4px;
  border-bottom: 2px solid colors.$interactive;
  color: colors.$interactive;
}
