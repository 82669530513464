@use 'styles/colors';
@use 'styles/utils';

$horizontal-padding: 16px;
$border-radius: 4px;

.contentContainer {
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
}

.header {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  z-index: 2;
}

.footer {
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.fullHeight {
  height: 100%;
}

.title {
  color: colors.$blue-500;
  display: flex;
  font-family: 'Amelia Basic', Helvetica, sans-serif;
  font-size: 30px;
  letter-spacing: -0.1rem;
  line-height: 1.3;
  @include utils.mr2;
}

.icon {
  color: colors.$interactive;
  cursor: pointer;
  height: 50px;
  padding: 8px 0 8px 8px;
  width: 42px;

  &:hover {
    color: colors.$interactive-hover;
  }
}

.content {
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: nowrap;
  height: 100%;
  min-height: 0;
}

.paddedContent {
  padding: 1.5rem $horizontal-padding;
}

.actionText {
  min-width: 1/3;
}
