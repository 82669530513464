@use 'styles/colors';
@use 'styles/utils';

.input {
  @include utils.p1;
  @include utils.mb0;
 border: utils.$base-border-style;
  border-radius: 4px;
  resize: none;
  transition: box-shadow 0.3s, border 0.5s;
  width: 100%;

  &:focus {
    outline: none;
    border: utils.$active-border-style;
    box-shadow: 0 0 4px 2px  colors.$box-shadow;
  }
}
.inputWrapper {
  flex-grow: 1;
  position: relative;
  .item {
    color: colors.$helper-text;
    background-color: colors.$white;
    cursor: pointer;
    font-size: 1rem;
    padding: 6px 12px;
  }
  .isHighlighted {
    background-color: colors.$white-highlight;
  }
  &.isError {
    .input {
      border: utils.$error-border-style;
      &:focus {
        box-shadow: inset 0.3em 0 0 0 colors.$error;
      }
    }
    .item {
      color: colors.$error;
    }
    .menu {
      border: utils.$error-border-style;
      border-top: none;
    }
  }
}
.menu {
 border: utils.$base-border-style;
  background-color: colors.$white;
  box-shadow: 0 0 4px 0 colors.$box-shadow;
  z-index: 10;
  border-radius: 3px;
  position: absolute;
  top: 8px !important;
  right: -1% !important;
  min-width: 102%;
  overflow: auto;
  max-height: 205px;
}
