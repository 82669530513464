@use 'styles/colors';

.errorIcon,
.errorText {
  color: colors.$red-700;
}

.successIcon,
.successText {
  color: colors.$green-700;
}
