@use 'styles/colors';
@use 'styles/utils';

.multiValueOption {
  padding: 3px 8px;
  width: unset;
  color: colors.$main-content-text;
  box-shadow: 0 0 2px 0 colors.$interactive;
  border: 2px solid colors.$interactive;
  background-color: colors.$white-highlight;  
  border-radius: 20px;
  font-size: 14px;
  margin: 1px;
  transition: all 0.15s linear;

  &:hover {
    background-color: colors.$white-highlight;
    border: utils.$base-border-style;
    color: colors.$white;
  }
}

.multiValueCheckIcon {
  margin-left: 4px;
  margin-top: 2px;
  flex-shrink: 0;
}


