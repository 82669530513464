@use 'styles/colors';
@use 'styles/utils';

.divider {
  @include utils.mb2;
  border-top: 2px solid colors.$gray-100;
  line-height: 1;
  min-height: 2px;
  box-sizing: border-box;
  width: 100%;
}

.top {
  @include utils.mt2;
}
