@use 'styles/colors';
@use 'styles/utils';

.ddContainer {
  @include utils.p2;
  background-color: colors.$white;
  border-radius: 4px;
  box-shadow: 0 0 0.5rem colors.$box-shadow;
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s;
  width: 100%;

  &:hover {
    background-color: colors.$white-highlight;
  }
}

.dragover {
  background-color: colors.$box-shadow;

  .innerDDContainer {
    border: 3px solid colors.$deep-aqua;
  }
}

.fileTypeError {
  background-color: colors.$error;

  .innerDDContainer {
    border: 3px solid colors.$error;
  }
}

.innerDDContainer {
  border: 3px dashed colors.$border-color;
  border-radius: 4px;
  height: 130px;
  pointer-events: none;
  transition: border-color 0.2s;
}

.actionText {
  font-weight: 600;
}

.linkText {
  color: colors.$interactive;
  text-decoration: underline;
}

.icon {
  @include utils.mr1;
  color: colors.$icon-active;
}

.fileInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
