@use 'styles/colors';
@use 'styles/utils';

.pagerBtn {
  width: 38px;
  height: 38px;
  margin-right: 4px;
  &:last-of-type {
    margin-right: 0px;
  }
}
