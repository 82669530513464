@use 'styles/colors';
@use 'styles/utils';
.listRow {
  border: utils.$base-border-style;
  min-height: 49px;
  transition: all 0.25s ease-out;
  width: 100%;
  background-color: white;
  padding: 8px 16px;
  color: colors.$gray-600;
  border-radius: 4px;
  margin-top: -1px;
  user-select: none;

  &--active {
    background-color: colors.$blue-000;
    border-color: colors.$blue-500;
  }

  &.selected {
    background-color: colors.$blue-000;
    border-color: colors.$blue-500;
    z-index: 15;
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      background-color: colors.$blue-000;
    }

    &:active {
      background-color: colors.$blue-100;
      cursor: e-resize;
    }
  }

  &.moveable {
    cursor: move;
    border-width: 1px;
    position: relative;
    margin-top: -1px;

    &:hover {
      background-color: colors.$blue-000;
    }

    &:active {
      border-color: colors.$yellow-500;
      background-color: colors.$blue-100;
      cursor: grabbing;
      z-index: 20;
    }
  }
}

.leftContent {
  padding-right: 16px;
  width: 100%;
}

.includeRowSelection {
  margin-right: 16px;
  cursor: pointer;
}

.checkbox {
  color: colors.$gray-600;

  &--active {
    color: colors.$blue-500;
  }
}

.rowNumber {
  margin-right: 16px;
  font-weight: 600;
  color: colors.$blue-900;
  flex-shrink: 0;
}

.avatarWrapper {
  min-height: 40px;
  min-width: 40px;
  flex-shrink: 0;
  margin-left: -2px;
  margin-right: 14px;
  cursor: crosshair;
  position: relative;
}

.avatar {
  position: absolute;

  &:hover {
    min-height: 100px;
    min-width: 100px;
    height: 100px;
    width: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.preTitleWrapper {
  margin-right: 16px;
  height: 100%;
}
.preTitleCustom {
  margin-right: 8px;
}
.preTitleText {
  margin-right: 16px;
  padding: 8px 0;
  min-width: 70px;
}
.accessibilityWidth {
  min-width: 90px;
}
.preTitleMainText {
  font-size: 16px;
  color: colors.$blue-900;
  font-weight: 600;
}
.preTitleMetaText {
  font-size: 14px;
  color: colors.$gray-600;
}
.preTitleColorBar {
  width: 8px;
  height: 100%;
  min-height: 40px;
  border-radius: 100px;
  background-color: colors.$blue-900;
  cursor: help;
}

.textWrapper {
  width: 100%;
}

.mainText {
  color: colors.$blue-900;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}

.connectionIcon {
  color: colors.$cardinal;
  margin-right: 4px;
  margin-top: 1px;
  cursor: help;
}
.colorDot {
  width: 14px;
  height: 14px;
  background-color: colors.$gray-500;
  border-radius: 50%;
  margin-top: 3px;
  margin-right: 4px;
  cursor: help;

  /**************************
    Color Dot Type Styles
  **************************/
  &.green {
    background-color: colors.$green-500;
  }

  &.blue {
    background-color: colors.$blue-500;
  }

  &.red {
    background-color: colors.$cardinal;
  }

  &.yellow {
    background-color: colors.$tangerine;
  }

  &.gray {
    background-color: colors.$gray-600;
  }
}

.titleText {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
}

.completed {
  text-decoration: line-through;
}

.labelText {
  font-size: 14px;
  color: colors.$gray-600;
}

.metaText {
  font-size: 14px;
  color: colors.$gray-600;
}

.rightContent {
  white-space: nowrap;

  > :last-child {
    margin-right: 0;
  }
}

.quickView {
  font-size: 14px;
  margin-right: 16px;

  > * {
    margin-right: 4px;
  }

  > :last-child {
    margin-right: 0;
  }
}

.actions {
  font-size: 14px;

  > * {
    margin-right: 4px;
  }

  > :last-child {
    margin-right: 0;
  }
}
