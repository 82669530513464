@use 'styles/colors';

.link:visited, .link:hover, .link:active, .link:link {
  text-decoration: underline;
  color: colors.$deep-aqua;
}

.body {
  background-color: white;
}

.buttonBox {
  margin-top: 20px;
}

.yesButton {
  margin-left: 4px;
  background-color: colors.$cardinal;
  color: white;
  border-width: 0px;
}