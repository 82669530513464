@use 'styles/colors';

.blue {
  color: colors.$interactive;
}

.container {
  display: inline-block;
}

.controlContainer {
  cursor: pointer;
}


.buttonDropStyles {
  border-radius: 100px;
  // This is height of input
  height: 42.3906px;
}