@use 'styles/colors';
@use 'styles/utils';

.sidebar {
  background-color: colors.$white;
  position: relative;
  border-left: utils.$base-border-style;
  transition: background-color 0.2s, margin-right 0.35s ease-in-out,
    margin-left 0.35s ease-in-out;
  z-index: 10;

  &--hidden {
    opacity: 0;
    transition: all 0s;
  }

  &--open {
    margin-right: 0px;
    margin-top: 0px;
  }

  &--closed {
    background-color: colors.$interactive;
    cursor: pointer;

    &:hover {
      background-color: colors.$interactive-hover;

      .arcBackground {
        fill: colors.$interactive-hover;
      }
    }
  }

  &--left {
    border-left: 0;
    border-right: utils.$base-border-style;
    .arc {
      left: 100%;
      transform: rotate(180deg);
    }

    .handleIcon {
      left: calc(100% - 7px);
    }
  }

  &--disableAnimate {
    transition: background-color 0.2s;
  }
}

.arc {
  bottom: 50px;
  cursor: pointer;
  fill: none;
  position: absolute;
  right: 100%;
  stroke: colors.$border-color;

  &--closed {
    .arcBackground {
      fill: colors.$interactive;

      &:hover {
        fill: colors.$interactive-hover;
      }
    }
  }
}

.arcBackground {
  stroke: none;
  fill: white;
  transition: fill 0.2s;
}

.handleIcon {
  bottom: 70px;
  color: colors.$interactive;
  pointer-events: none;
  position: absolute;
  right: calc(100% - 7px);
  transition: all 0.2s;

  &--closed {
    color:colors.$white;
    transform: rotate(180deg);
  }
}
