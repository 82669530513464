@use 'styles/colors';
@use 'styles/utils';
$base-border-style: utils.$base-border-style;
$cell-padding: 0.5rem 0.6rem;

.container {
  overflow-y: auto;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  font-size: 0.9rem;
  width: 100%;
}

.headerCell {
  background-color: colors.$gray-base;
  color: colors.$helper-text;
  border-bottom: $base-border-style;
  border-top: $base-border-style;
  font-size: 0.8rem;
  font-weight: 500;
  padding: $cell-padding;
  text-transform: uppercase;
  vertical-align: bottom;
  white-space: nowrap;
  z-index: 100;

  &:first-of-type {
    border-left: $base-border-style;
    border-top-left-radius: 4px;
  }

  &:last-of-type {
    border-right: $base-border-style;
    border-top-right-radius: 4px;
  }

  &--hoverable {
    cursor: pointer;
  }

  &--underlined:hover {
    text-decoration: underline;
  }

  &--sticky {
    position: sticky;
    top: 0;
  }
}

.sortIcon {
  color: colors.$interactive;
  margin-left: 0.2rem;
}

.bodyCell {
  border-bottom: $base-border-style;
  color: colors.$text;
  font-weight: 400;
  padding: $cell-padding;
  transition: border-color 0.2s linear;
  white-space: nowrap;

  &:first-of-type {
    border-left: $base-border-style;
  }

  &:last-of-type {
    border-right: $base-border-style;
  }

  &--centered {
    text-align: center;
  }
}

.hoverableRow {
  transition: background-color 0.2s linear;

  &:hover {
    background-color: colors.$livid-000;
    z-index: 100;
  }
}

.clickableRow {
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 4px 0 colors.$gray-300;
    z-index: 100;
  }
}

.highlighted {
  background-color: colors.$tangerine;

  &:hover {
    // overrides the hoverable row hover state
    background-color: colors.$tangerine;
  }
}

.hintable {
  border-bottom: 1px dashed colors.$midDarkGray;
}

.hint {
  max-width: 15rem;

  :global(.react-hint__content) {
    font-size: 0.9rem;
    text-align: center;
    width: 12rem;
  }
}

.checkbox {
  color: colors.$deep-gray;
  padding: 0px 0px 0px 4px !important;
  margin: 0px;
  width: 44px;
  vertical-align: middle !important;

  &--active {
    color: colors.$interactive;
  }
}

.full {
  width: 100%;
  height: 100%;
}

@media print {
  table {
    .headerCell--underlined {
      text-decoration: none;
    }

    .hoverableRow {
      background-color: colors.$white !important;
    }

    .bodyCell,
    .headerCell {
      white-space: initial;
    }
  }
}
