@use 'styles/colors';
@use 'styles/utils';

.wrapper {
  flex-shrink: 0;
}
.container {
  transition: all 0.2s linear;
  overflow: hidden;
  opacity: 0;
}
.openContainer {
  overflow: visible;
  opacity: 1;
}
.header {
  background-color: colors.$gray-100;
  border: utils.$base-border-style;
  border-radius: 4px;
  cursor: pointer;
  padding: 0.4rem 0.4rem;
  width: 100%;
}

.icon {
  color: colors.$blue-500;
  cursor: pointer;
  transition: transform 0.2s;

  &--collapsed {
    transform: rotate(180deg);
  }
}

.title {
  text-transform: uppercase;
  font-size: 0.9rem;
}
