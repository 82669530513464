@use 'styles/colors';

.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.selectable {
  cursor: pointer;
  .svg {
    fill: colors.$interactive;
  }
  &:hover .svg {
    fill: colors.$interactive-hover;
  }
}

.svg {
  fill: currentColor;
  path {
    fill: currentColor;
  }
}

.xsmall {
  width: 14px;
  height: 14px;
}

.small {
  width: 20px;
  height: 20px;
}
.medium {
  width: 28px;
  height: 28px;
}
.large {
  width: 40px;
  height: 40px;
}
.grow {
  height: 100%;
  width: 100%;
}
