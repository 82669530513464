@use 'styles/colors';

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  position: relative;
  display: inline-block;
  border-style: solid;
  border-color: colors.$deep-aqua;
  border-radius: 50%;
  animation: spin 0.75s infinite linear;

  &:before,
  &:after {
    left: -2px;
    top: -2px;
    display: none;
    position: absolute;
    content: '';
    width: inherit;
    height: inherit;
    border: inherit;
    border-radius: inherit;
  }
}

.light {
  border-color: colors.$white !important;
}
