@use 'styles/colors';

.labelText {
  margin-right: 8px;
}

.progressBar {
  height: 16px;
  min-width: 160px;
  background-color: colors.$gray-200;
  outline: 1px solid colors.$gray-300;
  border-radius: 100px;
  position: relative;
  cursor: help;
}

.progressFill {
  height: 16px;
  min-width: 16px;
  background-color: colors.$lime;
  position: absolute;
  border-radius: 100px;
  top: 0;
  left: 0;

  &.completed {
    background-color: colors.$blue-500;
  }

  &.error {
    background-color: colors.$cardinal;
  }
}
