@use 'styles/colors';
@use 'styles/utils';
.agendaItemBorder {
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 4px;
  align-self: stretch;
  flex: 0;
}
.eventStartTime {
  white-space: nowrap;

  &--cancelled {
    text-decoration: line-through;
  }
}
.agendaTimeColumn {
  align-items: flex-end;
  align-self: stretch;
  text-align: right;
}

.ended {
  background: colors.$gray-base;
  border: utils.$base-border-style;
  border-radius: 4px;
  color: colors.$helper-text;
  font-size: 0.65em;
  font-weight: 600;
  padding: 0.3em 0.6em;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
}

.assigned {
  background: colors.$blue-000;
  border: 1px solid colors.$blue-300;
  border-radius: 4px;
  color: colors.$blue-500;
  font-size: 0.65em;
  font-weight: 600;
  padding: 0.3em 0.6em;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
}

.eventName {
  padding-right: 3em;
  line-height: 1em;
  word-break: break-word;
}
