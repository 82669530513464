@use 'styles/utils';
@use 'styles/colors';

.circular {
  background-size: cover;
  background-repeat: no-repeat;
  border-color: colors.$white;
  border-width: 2px;
  border-radius: 50%;
  border-style: solid;
  box-shadow: utils.$shadow;
  flex-shrink: 0;
}
