   @use 'styles/colors';
@use 'styles/utils';

.button {
  border-width: 2px;
  border-radius: 4px;
  border-style: solid;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  height: 39px;
  letter-spacing: 0;
  outline: 0;
  padding: 0px 14px 0px 14px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.1s linear;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 0 0 4px 0 rgba(colors.$blue-900, 0.5);
  }

  &:focus {
    outline: 0;
  }

  &:active {
    box-shadow: 0 0 2px 0 rgba(colors.$blue-900, 0.5);

    .inner {
      transform: scale(0.95);
    }
  }

  &:disabled,
  &:disabled:focus {
    background: colors.$disabled-btn;
    border-color: colors.$disabled-btn;
    box-shadow: none;
    color: colors.$disabled-btn-text;
    cursor: not-allowed;
  }

  &:disabled:hover {
    color: colors.$disabled-btn-text-hover!important;
    background: colors.$disabled-btn!important;
    border-color: colors.$disabled-btn!important;
  }
}

.buttonText {
  padding-bottom: 3px;
}

.iconOnly {
  width: 39px;
}

.symbolIcon {
  margin-right: 8px;
}

.actionIcon {
  margin-left: 8px;
}

.mini {
  font-size: 0.78em;
  height: 24px;
  padding: 0px 8px;

  .actionIcon {
    margin-left: 6px;
  }

  .symbolIcon {
    margin-right: 6px;
  }

  .spinner {
    left: calc(50% - 7px);
    top: calc(50% - 7px);
  }

  &.iconOnly {
    width: 24px;
  }
}

.inner {
  transition: all 0.1s linear;
}

.loading {
  visibility: hidden;
}

.spinner {
  left: calc(50% - 10px);
  position: absolute;
  top: calc(50% - 10px);
}

/**************************
    Button Type Styles
**************************/
.required {
  border-color: colors.$required-btn;
  background: colors.$required-btn;
  color: white;

  &:hover,
  &:active {
    border-color: colors.$required-btn-hover;
    background: colors.$required-btn-hover;
    border-color: colors.$required-btn-hover;
  }
}

.primary {
  border-color: colors.$primary-btn;
  background: colors.$primary-btn;
  color: white;

  &:hover,
  &:active {
    background: colors.$primary-btn-hover;
    border-color: colors.$primary-btn-hover;
  }
}

.significant {
  border-color: colors.$significant-btn-border;
  background: colors.$significant-btn;
  color:colors.$significant-btn-text;
  border-radius: 2em;
 

  &:hover,
  &:active {
    background: colors.$significant-btn-hover;
  }
}

.selected {
  border-color: colors.$deep-aqua;
  border-radius: 2em;
  background: colors.$selected-btn;
  color:colors.$selected-btn-text;

  &:hover,
  &:active { 
    background: colors.$selected-btn-hover;
  }
}

.unselected {
  border-color: colors.$unselected-btn-border;
  background: colors.$unselected-btn;
  color: colors.$unselected-btn-text;
  border-radius: 2em;


  &:hover,
  &:active {
    background: colors.$unselected-btn-hover;
    border-color: colors.$unselected-btn-border-hover;
    color: colors.$unselected-btn-text-hover;
  }
}

.additional {
  border-color: colors.$additional-btn-border;
  background: colors.$additional-btn;
  color: colors.$additional-btn-text;

  &:hover,
  &:active {
    background: colors.$additional-btn-hover;
    border-color: colors.$navy;
    color: colors.$navy;
  }
}

.caution {
  border-color: colors.$caution-btn;
  background: colors.$caution-btn;
  color: colors.$caution-btn-text;

  &:hover,
  &:active {
    color: colors.$caution-btn-hover-border;
    background: colors.$caution-btn-hover;
    border-color: colors.$caution-btn-hover-border;
  }
}

.permanent {
  border-color: colors.$permanent-btn;
  background: colors.$permanent-btn;
  color: colors.$permanent-btn-text;

  &:hover,
  &:active {
    border-color: colors.$permanent-btn-hover;
    background: colors.$permanent-btn-hover         ;
  }
}

.exception {
  border-color: colors.$yellow-200;
  background: colors.$yellow-100;
  color: colors.$gray-500;

  &:hover,
  &:active {
    background: colors.$yellow-200;
  }
}

.outline {
  border-color: colors.$blue-500;
  background: colors.$white;
  color: colors.$blue-500;
  border-style: dashed;

  &:hover,
  &:active {
    background: colors.$blue-000;
  }
}

.floating {
  background: none;
  border-color: transparent;
  color: colors.$gray-600;

  &:hover,
  &:active {
    border-color: colors.$gray-200;
    background: colors.$gray-100;
  }
}

.fullWidth {
  width: 100%;
}
.ml {
  @include utils.ml1;
}
.mr {
  @include utils.mr1;
}

.keyShortcut {
  font-size: 12px;
  margin-top: -3px;
}
