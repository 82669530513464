@use 'styles/colors';
@use 'styles/utils';

$back-to-height: 38px;
.backTo {
  background-color: colors.$white;
  color: colors.$interactive;
  height: $back-to-height;
  text-transform: capitalize;
  &:hover {
    .backToText {
      border-bottom: 1px solid colors.$interactive;
    }

    .backToArrow {
      animation-name: bounce;
    }
  }
}
.backToArrow {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
.backToInner {
  @include utils.px1;
  cursor: pointer;
  height: $back-to-height;
}

.backToText {
  border-bottom: 1px solid transparent;
  font-size: 0.9rem;
  margin-left: 0.1rem;
  padding-right: 0.1rem;
  transition: border-bottom 0.2s linear;

  b {
    padding: 0 0.3rem 0 0.2rem;
  }
}
.spacer {
  @include utils.mt2;
}
