@use 'styles/colors';
@use 'styles/utils';

$dayGridMaxWidth: 60rem;

.dayGridHeader {
  @include utils.pb2;
  color: colors.$main-content-text;
  font-size: 1.2em;
  font-weight: bold;
  width: 100%;
  max-width: $dayGridMaxWidth;
}

.headerLabel {
  min-width: 14.28%;
  max-width: 14.28%;
  box-sizing: border-box;
  height: 49px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  padding-left: 10px;
  font-size: 1.2em;
  font-weight: bold;
  color: colors.$deep-gray;
}

.weekHeaderItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  min-width: 14.28%;
  max-width: 14.28%;
  box-sizing: border-box;
}

.weekday {
  cursor: pointer;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: colors.$helper-text;
  font-size: 1.4em;

  &.isSelected {
    background-color: colors.$interactive;
    color: white;
  }

  &:hover {
    border: 1px solid colors.$interactive;
  }
}

@media print {
  .weekday {
    display: none;
  }
}
