@use 'styles/colors';
@use 'styles/utils';

.messageAlert {
  @include utils.py1;
  min-height: 43px;

  &--noPadding {
    padding: 0;
  }
}

$border-type: utils.$base-border-style;
$border-radius: 4px;
.iconContainer {
  @include utils.px1;
  color: colors.$white;
  border: $border-type;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;

  &--success {
    background-color: colors.$success;
  }

  &--important {
    background-color: colors.$blue-500;
  }

  &--info,
  &--caution {
    background-color: colors.$gray-100;
    border: utils.$base-border-style;
    color: colors.$gray-500;
  }

  &--warning {
    background-color: colors.$warning;
  }

  &--error {
    background-color: colors.$error;
  }
}

.childrenContainer {
  align-items: center;
  @include utils.p1;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-style: italic;
  background-color: colors.$white;
  white-space: pre-line;
  border: $border-type;
  border-left-width: 0;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}