@use 'styles/colors';

.tableWrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 60px;
}
.table {
  table-layout: fixed;
  margin-left: 2%;
  width: 96%;
  height: 100%;
  td {
    vertical-align: top;
    white-space: pre-line;
  }
}
.cancelled {
  text-decoration: line-through;
}
