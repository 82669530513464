@use 'styles/colors';
@use 'styles/utils';

.input {
  color: colors.$main-content-text;
  border: utils.$base-border-style;
  overflow-x: hidden;
  border-radius: 4px;
  margin-bottom: 0;
  padding: 8px 14px;
  font-size: 16px;
  resize: none;
  transition: box-shadow 0.3s, border 0.5s;
  width: 100%;
  white-space: pre-wrap;
  min-height: 39px;
  line-height: 1.4;

  &.fullWidth {
    width: 100%;
  }

  &.iconInput {
    padding-left: 32px;
  }

  &:disabled {
    border-color: colors.$disabled;
    cursor: not-allowed;
    &::placeholder {
      color: colors.$disabled;
    }
  }

  &:focus {
    outline: none;
    border: utils.$active-border-style;
    box-shadow: 0 0 4px 2px rgba(colors.$interactive, 0.4);
  }

  &::placeholder {
    color: colors.$helper-text;
    font-size: 0.9rem;
  }
}
.inlineBlock {
  display: inline-block;
  position: relative;
  width: 100%;
}
.icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: colors.$interactive;
}
.isError {
  @include utils.errorBorder;

  &:focus {
    border: 1px solid colors.$cardinal;
    box-shadow: inset 0.3em 0 0 0 colors.$cardinal;
  }
}
.checkbox {
  height: 16px;
  width: 16px;
}
