@use 'styles/colors';
@use 'styles/utils';

:global {
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    color: colors.$white!important;
    background-color: colors.$interactive!important;
    font-weight: bold;
    &:hover {
      background-color: colors.$interactive-hover;
      color:colors.$white;
    }
  }
  .highlight-day {
    background-color: colors.$emerald;
    border: utils.$base-border-style;
    border-radius: 5px;
    &.react-datepicker__day--selected {
      color: colors.$interactive;
    }
  }
  .react-datepicker__day-name {
    color: colors.$deep-gray;
  }

  .react-datepicker-popper {
    top: -2px !important;
    z-index: 1000 !important;
  }
  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation--previous {
    border-right-color: colors.$interactive;
    &:hover {
      border-right-color: colors.$navy;
    }
  }
  .react-datepicker__navigation--next {
    border-left-color: colors.$interactive;
    &:hover {
      border-left-color: colors.$navy;
    }
  }
  .react-datepicker-wrapper {
    width: 100%;

    input {
      min-height: 40px;
      padding: 8px;
      padding-left: 10px;
      border: utils.$base-border-style;
      margin-bottom: 0px;
      border-radius: 4px;
      transition: box-shadow 0.3s, border 0.5s;
      width: 100%;

      &:focus {
        outline: none;
        border: 1px solid colors.$deep-aqua;
        box-shadow: 0 0 4px 2px  colors.$box-shadow;
      }

      &:disabled {
        color: colors.$disabled;
        border-color: colors.$disabled;
      }
    }
  }

  .react-datepicker {
    border: utils.$base-border-style;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
  .react-datepicker__header {
    background-color: colors.$gray-base;
    border-bottom:utils.$base-border-style;
  }
  .react-datepicker__current-month {
    font-weight: 700;
    color: colors.$main-content-text;
  }
}
