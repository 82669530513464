@use 'styles/colors';
@use 'styles/utils';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.drawer {
  background-color: colors.$white;
  border-radius: 4px;
  box-shadow: 0 0 4px 1px colors.$box-shadow;
  display: flex;
  z-index: 100;
  overflow: hidden;
}
.darkShadow {
  box-shadow: 0 0 4px 1px colors.$box-shadow;
  background-color: colors.$box-shadow;
  border: 1px solid  colors.$box-shadow;
}
.drawerWrapper {
  margin-top: 40px;
  max-height: 0px;
  position: absolute;
  transition: max-height 0.08s linear, top 0.1s linear, visibility 0.1s linear;
  visibility: hidden;
  overflow: hidden;
  z-index: 99;
}
.drawerWrapperShowing {
  max-height: 500px;
  visibility: visible;
  overflow: visible;
}
