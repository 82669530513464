@use 'styles/colors';
@use 'styles/utils';

$border-radius: 5px;
$form-height: 75vh;

.container {
  @include utils.m2;
}

.form {
  margin: 0;
}

.formContainer {
  background-color: colors.$white;
  border-bottom-left-radius: $border-radius;
  border-top-left-radius: $border-radius;
  @include utils.p3;
  min-height: 100%;
  overflow-x: hidden;
}

.scrollable {
  max-height: $form-height;
  overflow-y: auto;
}

.formHeader {
  @include utils.pb2;
}

.link {
  text-decoration: underline;
  color: colors.$interactive;
  transition: color 0.2s;

  &:hover {
    color: colors.$interactive-hover;
  }
}

.imageContainer {
  position: relative;
}

.image {
  border-bottom-right-radius: $border-radius;
  border-top-right-radius: $border-radius;
  max-height: $form-height;
}

.brandedBackgroundImage {
  object-fit: cover;
  object-position: 36% 0%;
  width: 350px;
  height: 100%;
  opacity: 56%;
}

.brandedImageContainer {
  background-color: black;
}

.brandedLogoImg {
  width: 200px;
}

.brandedLogoSubtext {
  @include utils.mt2;
  color: white;
  text-align: center;
}

.brandedLogoContainer {
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  top: 36%;
}

.submitBtn {
  @include utils.mt2;
  min-height: 40px;
}

.mobileBrandHeader {
  display: none;
}

@media screen and (max-width: 800px), screen and (max-height: 500px) {
  .formContainer {
    border-radius: $border-radius;
    max-width: 400px;
    width: 90vw;
  }

  .imageContainer {
    display: none;
  }

  .mobileBrandHeader {
    display: block;
    text-align: center;
    background: colors.$branded-gray;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .mobileBrandedLogoImg {
    width: 75px;
  }
}
