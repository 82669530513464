@use 'styles/colors';
@use 'styles/utils';
.filter {
  white-space: nowrap;
  height: 39px;
  padding: 2px 14px 0px 14px;
  margin-right: 4px;
  background-color: colors.$gray-100;
  border: utils.$base-border-style;
  color: colors.$gray-600;
  border-radius: 100px;
  font-weight: 600;
  font-size: 16px;
  cursor: copy;

  &:hover {
    border-color: colors.$blue-500;
  }

  &:active {
    cursor: wait;
  }
}

.selected {
  background-color: colors.$blue-000;
  border: 1px solid colors.$blue-500;
  color: colors.$blue-900;
  cursor: pointer;
}
