@use 'styles/colors';
@use 'styles/utils';

.dropdown {
  cursor: default;
  position: absolute;
  z-index: 9999;
}

.dropdownContent {
  background-color: colors.$navy;
  color: white;
  max-height: 500px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 0;
  border-radius: 4px;
  padding: 8px 0;
}

.actionOption {
  cursor: pointer;
  padding: 6px 12px;
  white-space: nowrap;
  transition: background-color 0.1s linear;

  &:hover {
    background-color: colors.$interactive;
  }
}

.actionIcon {
  color: colors.$white;
  margin-right: 0.6rem;
}

.linkText {
  color: colors.$white;
}

.svgIcon {
  height: 16px;
  width: 16px;
}

.targetIcon {
  transition: all 0.2s linear;
  cursor: pointer;
  &:hover {
    color: colors.$interactive;
  }
}
