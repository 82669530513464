@use 'styles/colors';

.link:visited, .link:hover, .link:active, .link:link {
  text-decoration: underline;
  color: colors.$deep-aqua;
}

.body {
  background-color: white;
}
