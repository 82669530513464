@use 'styles/colors';
@use 'styles/utils';

.calendarHolidayDay {
    font-size: 0.8em;
    line-height: 1em;
    margin: 0.2em 0;
    padding: 4px;
    border-radius: 2px;
    background-color: colors.$gray;
    overflow: hidden;

    &NoText {
        background-color: transparent;
    }

    &--print {
        font-size: 1em;
        padding: 2px;
        background-color: colors.$gray;
        border-radius: 2px;
        line-height: 1em;
    }

    &--weekPrint {
        font-size: 1.5em;
        padding: 2px;
        background-color: colors.$gray;
        border-radius: 2px;
        line-height: 1em;
    }
}

.bell {
    margin-right: 2px;
    min-width: 10px;

    &Small {
        margin-right: 2px;
        fill: colors.$navy;
        min-width: auto;
    }
}