@use 'styles/utils';
@use 'styles/colors';

.item {
  @include utils.mr1;
}

.clickable {
  cursor: pointer;
}

.row {
  @include utils.mb1;
}

.chevron {
  @include utils.mr1;
  font-size: 1.2rem;
  width: 20px;
}
.content {
  @include utils.p2;
}
